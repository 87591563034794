let api_host = `/v1`;
let botzilla_roles_path = 'botzilla_roles';

export { api_host };

export default {
  // user
  login: `${api_host}/user/login`,
  logout: `${api_host}/user/logout`,
  register: `${api_host}/user/register`,
  setting: `${api_host}/user/setting`,
  user_info: `${api_host}/user/info`,
  tenant_info: `${api_host}/user/tenant_info`,
  set_tenant_info: `${api_host}/user/set_tenant_info`,

  // llm model
  factories_list: `${api_host}/llm/factories`,
  llm_list: `${api_host}/llm/list`,
  my_llm: `${api_host}/llm/my_llms`,
  set_api_key: `${api_host}/llm/set_api_key`,
  add_llm: `${api_host}/llm/add_llm`,
  delete_llm: `${api_host}/llm/delete_llm`,

  // knowledge base
  kb_list: `${api_host}/kb/list`,
  create_kb: `${api_host}/kb/create`,
  update_kb: `${api_host}/kb/update`,
  rm_kb: `${api_host}/kb/rm`,
  get_kb_detail: `${api_host}/kb/detail`,

  // chunk
  chunk_list: `${api_host}/chunk/list`,
  create_chunk: `${api_host}/chunk/create`,
  set_chunk: `${api_host}/chunk/set`,
  get_chunk: `${api_host}/chunk/get`,
  switch_chunk: `${api_host}/chunk/switch`,
  rm_chunk: `${api_host}/chunk/rm`,
  retrieval_test: `${api_host}/chunk/retrieval_test`,
  knowledge_graph: `${api_host}/chunk/knowledge_graph`,

  // document
  get_document_list: `${api_host}/document/list`,
  document_change_status: `${api_host}/document/change_status`,
  document_rm: `${api_host}/document/rm`,
  document_delete: `${api_host}/api/document`,
  document_rename: `${api_host}/document/rename`,
  document_create: `${api_host}/document/create`,
  document_run: `${api_host}/document/run`,
  document_change_parser: `${api_host}/document/change_parser`,
  document_thumbnails: `${api_host}/document/thumbnails`,
  get_document_file: `${api_host}/document/get`,
  document_upload: `${api_host}/document/upload`,
  web_crawl: `${api_host}/document/web_crawl`,
  document_infos: `${api_host}/document/infos`,
  upload_and_parse: `${api_host}/document/upload_and_parse`,

  // chat
  setDialog: `${api_host}/dialog/set`,
  getDialog: `${api_host}/dialog/get`,
  removeDialog: `${api_host}/dialog/rm`,
  listDialog: `${api_host}/dialog/list`,
  setConversation: `${api_host}/conversation/set`,
  getConversation: `${api_host}/conversation/get`,
  listConversation: `${api_host}/conversation/list`,
  removeConversation: `${api_host}/conversation/rm`,
  completeConversation: `${api_host}/conversation/completion`,
  deleteMessage: `${api_host}/conversation/delete_msg`,
  thumbup: `${api_host}/conversation/thumbup`,
  tts: `${api_host}/conversation/tts`,
  ask: `${api_host}/conversation/ask`,
  mindmap: `${api_host}/conversation/mindmap`,
  getRelatedQuestions: `${api_host}/conversation/related_questions`,
  // chat for external
  createToken: `${api_host}/api/new_token`,
  listToken: `${api_host}/api/token_list`,
  removeToken: `${api_host}/api/rm`,
  getStats: `${api_host}/api/stats`,
  createExternalConversation: `${api_host}/api/new_conversation`,
  getExternalConversation: `${api_host}/api/conversation`,
  completeExternalConversation: `${api_host}/api/completion`,
  uploadAndParseExternal: `${api_host}/api/document/upload_and_parse`,

  // file manager
  listFile: `${api_host}/file/list`,
  uploadFile: `${api_host}/file/upload`,
  removeFile: `${api_host}/file/rm`,
  renameFile: `${api_host}/file/rename`,
  getAllParentFolder: `${api_host}/file/all_parent_folder`,
  createFolder: `${api_host}/file/create`,
  connectFileToKnowledge: `${api_host}/file2document/convert`,
  getFile: `${api_host}/file/get`,
  moveFile: `${api_host}/file/mv`,

  // system
  getSystemVersion: `${api_host}/system/version`,
  getSystemStatus: `${api_host}/system/status`,

  // flow
  listTemplates: `${api_host}/canvas/templates`,
  listCanvas: `${api_host}/canvas/list`,
  getCanvas: `${api_host}/canvas/get`,
  removeCanvas: `${api_host}/canvas/rm`,
  setCanvas: `${api_host}/canvas/set`,
  resetCanvas: `${api_host}/canvas/reset`,
  runCanvas: `${api_host}/canvas/completion`,

  testDbConnect: `${api_host}/canvas/test_db_connect`,

  //Botzilla roles
  getAllUsers: `${api_host}/${botzilla_roles_path}/get_all_users`,
  getAllCompanies: `${api_host}/${botzilla_roles_path}/get_all_companies`,
  setUserRoleCompany: `${api_host}/${botzilla_roles_path}/set_user_role_company`,
  getUserLlms: `${api_host}/${botzilla_roles_path}/get_user_llms`,
  addUserLlms: `${api_host}/${botzilla_roles_path}/add_llm_user`,
  deleteUserLlms: `${api_host}/${botzilla_roles_path}/delete_llm_user`,
  companyCreateUser: `${api_host}/${botzilla_roles_path}/company_user_register`,
  getMyCompanyLimits: `${api_host}/${botzilla_roles_path}/get_my_company_limits`,
  getTokenCountByLlm: `${api_host}/${botzilla_roles_path}/get_token_count_by_llm`,
  getTokenCountByCompany: `${api_host}/${botzilla_roles_path}/get_token_count_by_company`,
  getMyCompanyUsers: `${api_host}/${botzilla_roles_path}/get_my_company_users`,
  deleteCompanyUser: `${api_host}/${botzilla_roles_path}/delete_company_user`,
  deleteUserDialog: `${api_host}/${botzilla_roles_path}/delete_user_dialog`,
  addUserDialog: `${api_host}/${botzilla_roles_path}/add_user_dialog`,
  companyRegister: `${api_host}/${botzilla_roles_path}/company_register`,
  getAllCompaniesWithLimits: `${api_host}/${botzilla_roles_path}/get_all_companies_with_limits`,
  deleteCompany: `${api_host}/${botzilla_roles_path}/delete_company`,
  changeCompanyLimits: `${api_host}/${botzilla_roles_path}/change_company_limits`,
  getUserDialogs: `${api_host}/${botzilla_roles_path}/get_user_dialogs`,
  sendMailForm: `${api_host}/${botzilla_roles_path}/send_mail_form`,
  disableCompany: `${api_host}/${botzilla_roles_path}/disable_company`,
  disableCompanyUser: `${api_host}/${botzilla_roles_path}/disable_company_user`,
  resetPassword: `${api_host}/${botzilla_roles_path}/forgot_password`,
  changeIframeImage: `${api_host}/${botzilla_roles_path}/change_iframe_image`,
  getIframeImage: `${api_host}/${botzilla_roles_path}/get_iframe_image`,
  sendFeedback: `${api_host}/${botzilla_roles_path}/send_feedback`,
};
